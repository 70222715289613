import React from 'react';

import * as Components from '../Components.js';
//import * as Generation from '../Generation.js';
import * as Helpers from '../Helpers.js';
import * as HomePage from './HomePage.js';
import * as Geoji from './Geoji.js';
import { DateObject } from "react-multi-date-picker"

// import the Darwin API classes
import API from '../API.js';
import logo from '../images/GeojiLogoGreenBlue.svg';
// import ImageBlueTickets from '../images/BlueTickets.svg';
import ImageBlueGraph from '../images/BlueGraph.svg';
import ImageInfinity from '../images/Infinity.svg';
import ImageClock from '../images/Clock.svg';
// import ImageLocation from '../images/Location.svg';
// import ImageMessage from '../images/Message.svg';
// import ImageWebsite from '../images/Website.svg';


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

export class Dashboard extends React.Component {

  constructor(props) {
    super(props)

    let data = {
      underground: {
        value: "0",
        valid: true
      },
      forever: {
        value: "0",
        valid: true
      }
    }

    let newState = {
      loading: false,
      data: data,
      forceCheck: false,
      shakeButton: false,
      error: false,

      view: "home",
      subview: false,

      geojis: [],

      searchIndex: 0,
      searchLikeIndex: 0,
      emojis: [],
      likeEmojis: [],

      selectedToken: false,
    }
    this.possibleViews = ["home", "geojis", "tickets", "create"]

    if (props.dashboardView !== undefined && props.dashboardView !== false) {
      // console.log("Handle passed in through props", props)
      //make sure it is an option.
      if (this.possibleViews.includes(props.dashboardView)) {
        newState.view = props.dashboardView
      }
    }
    if (props.dashboardView2 !== undefined && props.dashboardView2 !== false) {
      newState.subview = props.dashboardView2
    }

    this.state = newState

    this.submitForm = this.submitForm.bind(this)
    this.formChanged = this.formChanged.bind(this)
    this.shakeTheButton = this.shakeTheButton.bind(this)
  }

  componentDidMount() {
    this.calculateView()
  }

  componentDidUpdate(prevProps) {
    //console.log("purchaseID", this.props.purchaseID, prevProps.purchaseID)
    if (this.props.dashboardView !== prevProps.dashboardView) {
      var newDashboardView = "home"
      if (this.props.dashboardView !== undefined && this.props.dashboardView !== false) {
        newDashboardView = this.props.dashboardView
      }

      var newView2 = false
      if (this.props.dashboardView2 !== undefined && this.props.dashboardView2 !== false) {
        newView2 = this.props.dashboardView2
      }

      console.log("Dashboard View Updated", newDashboardView, newView2)
      //make sure it is an option.
      if (this.possibleViews.includes(newDashboardView)) {
        this.setState({
          view: newDashboardView,
          subview: newView2,
          geoji: false,
          selectedToken: false,
        }, () => {
          this.calculateView()
        })
      }
    }
  }

  calculateView() {
    //load the data for this user.
    if (this.state.view === "geojis") {
      this.loadMyGeojis()
    } else if (this.state.view === "create") {
      //load the geoji data if necessary
      if (this.state.subview !== false) {
        this.loadGeoji()
      } else {
        //reset to empty the forms.
        this.setState((old) => {
          let newData = {
            underground: {
              value: "0",
              valid: true
            },
            forever: {
              value: "0",
              valid: true
            },
            emoji: {
              value: "🎸",
              valid: true,
            },
            emojiElement: {
              value: "🎸",
              valid: true,
            },
            photoURL: {
              value: "",
              valid: false,
            },
            title: {
              value: "",
              valid: false,
            }
          }
          let newGeoji = {
            tokens: []
          }
          return {
            geoji: newGeoji,
            loading: false,
            error: false,
            data: newData
          }
        }, () => {
          this.searchEmojis()
          this.searchLikeEmojis()
        })
      }
    }
  }

  loadGeoji() {
    console.log("loadGeoji", this.state.subview)
    this.setState({
      loading: true
    }, () => {
      API.callDarwinAPI("GET", "geoji/" + this.state.subview, {}, (result) => {
        console.log("result", result)
        if ("error" in result) {
          console.log("Error GET geoji/" + this.state.subview, result)
          this.setState({
            loading: false,
          }, () => {
            this.showError("No Access", "You don't have access to edit this Geoji.")
            this.props.changeView("Dashboard", "geojis")
          })
          return
        }

        let geoji = Helpers.formatGeoji(result.data.geoji)
        console.log("geoji", geoji)

        //show an error if we don't have edit access
        if (!Helpers.userHasRevenueAccess(this.props.userInfo.user, geoji)) {
          this.setState({
            loading: false,
          }, () => {
            this.showError("No Access", "You don't have access to edit this Geoji.")
            this.props.changeView("Dashboard", "geojis")
          })
          return
        }

        this.setState((old) => {
          let newData = old.data
          newData.title = {
            value: geoji.title,
            valid: true
          }
          newData.address = {
            value: geoji.where,
            valid: true
          }
          newData.emoji = {
            value: geoji.emoji,
            valid: true
          }
          newData.emojiElement = {
            value: geoji.emoji,
            valid: true
          }
          newData.underground = {
            value: geoji.private,
            valid: true
          }
          if (geoji.eventDates && geoji.eventDates.length > 0) {
            newData.dates = {
              value: geoji.eventDates,
              valid: true
            }
          }

          if (geoji.when && geoji.when.length > 0) {
            newData.when = {
              value: geoji.when,
              valid: true
            }
          }
          newData.forever = {
            value: geoji.infinite ? "1" : "0",
            valid: true
          }
          if (geoji.photoURL && geoji.photoURL.length > 0) {
            newData.photo = {
              value: geoji.photoURL,
              valid: true
            }
          }
          if (geoji.description && geoji.description.length > 0) {
            newData.details = {
              value: geoji.description,
              valid: true
            }
          }
          if (geoji.websiteURL && geoji.websiteURL.length > 0) {
            newData.website = {
              value: geoji.websiteURL,
              valid: true
            }
          }
          if (geoji.contactInfo && geoji.contactInfo.length > 0) {
            newData.contact = {
              value: geoji.contactInfo,
              valid: true
            }
          }

          return {
            geoji: geoji,
            loading: false,
            error: false,
            data: newData
          }
        }, () => {
          this.searchEmojis()
          this.searchLikeEmojis()
        })
      })
    })
  }

  loadMyGeojis() {
    this.setState({
      loading: true,
      error: false,
    }, () => {
      API.callDarwinAPI("GET", "myGeojis", {}, (result) => {
        console.log("result", result)
        if ("error" in result) {
          console.log("Error GET my geojis", result)
          this.setState({
            loading: false,
            error: "Invalid link.",
          })
          return
        }

        let geojis = []
        for (let value of Object.values(result.data.geojis)) {
          geojis.push(Helpers.formatGeoji(value))
        }
        geojis.sort((a, b) => {
          let aNext = Helpers.geojiNextEventDate(a)
          if (aNext === false) {
            aNext = Helpers.parseSQLDate(a.endDate)
          }
          if (a.infinite) {
            aNext = Helpers.year3000
          }
          let bNext = Helpers.geojiNextEventDate(b)
          if (bNext === false) {
            bNext = Helpers.parseSQLDate(b.endDate)
          }
          if (b.infinite) {
            bNext = Helpers.year3000
          }

          if (aNext < bNext) {
            return 1
          } else if (aNext > bNext) {
            return -1
          } else {
            if (a.title.toLowerCase() < b.title.toLowerCase()) {
              return -1
            } else if (a.title.toLowerCase() > b.title.toLowerCase()) {
              return 1
            }
            return 0
          }
        })
        console.log("geojis", geojis)

        this.setState({
          geojis: geojis,
          loading: false,
          error: false,
        })
      })
    })
  }

  /*
  * Submits the form
  */
  submitForm() {
    let results = this.checkForm(true)
    if (results.valid) {
      switch (this.state.view) {
        case "create":
          if (this.state.selectedToken !== false) {
            this.finishedEditingToken(results.data)
          } else {
            this.createGeoji(results.data)
          }
          break;
        default:
          console.error("Unhandled submitForm", this.state.view)
          break;
      }
    } else {
      console.log("Form not valid to submit")
      this.setState({
        forceCheck: true
      })
      //Shake the submit button as it is invalid to submit
      this.shakeTheButton()

      if (this.state.selectedToken !== false) {
        this.showError("Name this Token", "Set the name like '🎟 General Admission' or '👕 T-Shirt'")
      } else {
        if (!this.state.data.emojiElement || !this.state.data.emojiElement.valid) {
          this.showError("Select an Emoji", "Please tap on a Emoji to set it.")
        }
      }
    }
  }

  /*
  Checks the form to make sure it is valid.
  Returns {valid:Bool, data:{}}
  */
  checkForm(print = false) {
    //1) Make sure we have all of the data.
    let valid = true
    let requiredFields = []
    let requiredIf = []
    let requiredSometimes = [] //if set, then don't remove it from the data.
    let optionals = [] //the optional fields apart from the requiredFields.
    switch (this.state.view) {
      case "create":
        if (this.state.selectedToken !== false) {
          requiredFields = ["tokenName", "tokenPrice", "tokenQuantity", "tokenFrozen", "tokenUserHidden"]
          optionals = ["tokenAbout", "tokenAboutImage", "tokenPurchaseLimit"]
          requiredIf = []
          requiredSometimes = []
        } else {
          requiredFields = ["address", "title", "emojiElement", "underground", "forever"]
          optionals = ["dates", "when", "photo", "details", "website", "contact"]
          requiredIf = [{
            "require": "dates",
            "field": "forever",
            "values": ["0"]
          }, {
            "require": "when",
            "field": "forever",
            "values": ["1"]
          }]
          requiredSometimes = ["when"]
        }
        break;
      default:
        break;
    }
    requiredFields.forEach((element) => {
      if (this.state.data[element] === undefined || this.state.data[element].value === undefined) {
        //the field is not filled out
        if (print) {
          console.log("required not filled out: ", element)
        }
        valid = false
      }
    })
    requiredIf.forEach((condition) => {
      if (this.state.data[condition.field] === undefined || this.state.data[condition.field].value === undefined) {
        //the condition field is not filled out
        if (print) {
          console.log("condition field is not filled out: ", condition)
        }
        valid = false
      } else if (condition.values !== undefined && condition.values.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          if (print) {
            console.log("rif field is required and is not filled out: ", condition)
          }
          valid = false
        }
      } else if (condition.not !== undefined && !condition.not.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          if (print) {
            console.log("rif field is required with not and is not filled out: ", condition)
          }
          valid = false
        }
      }
    })
    //2) Make sure every data element is valid.
    for (let key in this.state.data) {
      let forget = false
      //Make sure it is not a requiredSometimes
      if (!requiredSometimes.includes(key)) {
        // Check the requiredIf conditions
        for (let i = 0; i < requiredIf.length; i = i + 1) {
          let rif = requiredIf[i]
          if (rif.require === key) {
            if (!rif.values.includes(this.state.data[rif.field].value)) {
              if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                forget = true
              }
            }
            break
          }
        }
      }
      //Make sure this is a field we are looking for.
      if (optionals.includes(key) || requiredFields.includes(key)) {
        if (!forget && this.state.data[key].valid === false) {
          if (print) {
            console.log("data not valid", key)
          }
          valid = false
        }
      }
    }

    //3) If valid, return the data, else force check the fields for display.
    if (valid) {
      let data = {}
      for (let key in this.state.data) {
        let forget = false
        //Make sure it is not a requiredSometimes
        if (!requiredSometimes.includes(key)) {
          for (let i = 0; i < requiredIf.length; i = i + 1) {
            let rif = requiredIf[i]
            if (rif.require === key) {
              if (!rif.values.includes(this.state.data[rif.field].value)) {
                if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                  forget = true
                }
              }
              break
            }
          }
        }
        if (!forget) {
          if (optionals.includes(key) || requiredFields.includes(key)) {
            if (this.state.data[key].value !== null && this.state.data[key].value.toString().length > 0) {
              data[key] = this.state.data[key].value
              if (typeof data[key] === 'string') {
                data[key] = data[key].trim()
              }
            }
          }
        }
      }
      return {
        valid: true,
        data: data
      }
    } else {
      return {
        valid: false,
        data: "Form not valid to submit"
      }
    }
  }

  /*
  * Called when data in an input form changes.
  * This will update the state.data param with the
  * name and new value of the form element.
  */
  formChanged(name, value, valid) {
    //console.log(name, value, valid);
    this.setState((prevState) => {
      let d = prevState.data
      d[name] = {
        value: value,
        valid: valid
      }
      return {
        data: d,
        forceCheck: false,
        changesMade: true,
      }
    }, () => {
      //check to see if the form is valid to submit
      let res = this.checkForm()
      this.setState({
        valid: res.valid
      }, () => {
        if (name === "title" || name === "details") {
          this.searchEmojis()
        } else if (name === "emoji") {
          this.searchLikeEmojis()
        }
      })
    })
  }

  onToggle(name, newVal) {
    console.log(name, newVal)
    if (name === "tokenFrozen") {
      if (newVal === "0") {
        this.formChanged(name, "1", true)
      } else {
        this.formChanged(name, "0", true)
      }
    } else if (name === "tokenUserHidden") {
      if (newVal === "0") {
        this.formChanged(name, 1, true)
      } else {
        this.formChanged(name, 0, true)
      }
    } else {
      this.formChanged(name, newVal, true)
    }
  }

  /*
  Shakes the button and then removes the class.
  */
  shakeTheButton() {
    this.setState({
      shakeButton: true
    }, () => {
      setTimeout(() => {
        this.setState({
          shakeButton: false
        })
      }, 1000)
    })
  }

  backHome() {
    console.log("Back Home")
  }

  changeStateView(newView) {
    this.props.changeView("Dashboard", newView)
  }

  editGeoji(geoji) {
    this.props.changeView("Dashboard", "create", geoji.geojiID)
  }

  viewGeoji(geoji) {
    this.props.changeView("Geoji", geoji.geojiID)
  }

  /*
  An emoji has been selected from the first list.
  */
  emojiSelected(emoji) {
    this.formChanged("emoji", emoji, true)
    this.formChanged("emojiElement", emoji, true)
  }

  /*
  Searches for similar Emojis.
  */
  searchLikeEmojis() {
    let si = 0
    this.setState((old) => {
      si = old.searchLikeIndex + 1
      return {
        searchLikeIndex: si
      }
    }, () => {
      setTimeout(() => {
        this.searchLikeEmojis2(si)
      }, 300)
    })
  }

  /*
  Searches for similar Emojis.
  */
  searchLikeEmojis2(indi) {
    if (this.state.searchLikeIndex !== indi) {
      //console.log("Search Interupted")
      return
    }
    let search = ((this.state.data.emoji && this.state.data.emoji.value) ? this.state.data.emoji.value : "🎪")
    let emojis = Helpers.searchLikeEmojis(search, 30).map((emo) => {
      return emo[0]
    })
    if (emojis.length === 0) {
      emojis = Helpers.searchLikeEmojis("🎪", 30).map((emo) => {
        return emo[0]
      })
    }
    this.setState({
      likeEmojis: emojis
    })
  }

  /*
  Starts the searching for the emojis.
  */
  searchEmojis() {
    let si = 0
    this.setState((old) => {
      si = old.searchIndex + 1
      return {
        searchIndex: si
      }
    }, () => {
      setTimeout(() => {
        this.searchEmojis2(si)
      }, 300)
    })
  }

  /*
  Actually searches for the emojis and returns the results.
  */
  searchEmojis2(indi) {
    if (this.state.searchIndex !== indi) {
      //console.log("Search Interupted")
      return
    }
    //console.log("Searching Emojis")
    let search = (this.state.data.title ? this.state.data.title.value : "") + " " + (this.state.data.details ? this.state.data.details.value : "")
    if (search === " " || search === "") {
      search = "bar event event party stadium music comedy"
    }
    let emojis = Helpers.searchEmojis(search, 30).map((emo) => {
      return emo[0]
    })
    this.setState({
      emojis: emojis
    })
  }

  /*
  A Google Address was selected, let's update the fields
  */
  googleAddressSelected(address) {
    console.log("addressSelected:", address)
    this.formChanged("address", address.description, true)
  }

  /*
  * Shows a confirmation dialog with an optional callback passing true or false
  * to whether they have accepted the action
  */
  showConfirmation(title, description, accept, deny, action) {
    this.setState({
      confirmation: true,
      confirmationTitle: title,
      confirmationDescription: description,
      confirmationAccept: accept,
      confirmationDeny: deny,
      confirmationAction: (result) => {
        this.setState({
          confirmation: false
        })
        action(result)
      }
    })
  }

  /*
  Shows an error overlay that can be dismissed.
  */
  showError(title, description) {
    this.setState({
      showError: true,
      showErrorTitle: title,
      showErrorDescription: description,
      showErrorAction: (result) => {
        this.setState({
          showError: false
        })
      }
    })
  }

  /*
  Creates/Saves changes to the Geoji with the provided data.
  */
  createGeoji(data) {
    console.log("create the geoji", data)

    if (data.forever === "0" && (!data.dates || data.dates.length === 0)) {
      this.showError("Select a Date", "Select a date for your event or if this is a recurring business, turn on the forever toggle.")
      return
    } else if (data.forever === "1" && (!data.when || data.when.length === 0)) {
      this.showError("Set the Time", "What time of day is your recurring business/event open? '8am - 6pm?', 'all day', etc.")
      return
    }

    let gdata = {}
    gdata.emoji = data.emojiElement
    gdata.baseEmoji = data.emojiElement
    gdata.title = data.title
    //format the dates
    if (data.dates && data.dates.length > 0) {
      let dateString = ""
      for (let i = 0; i < data.dates.length; i = i + 1) {
        if (dateString.length > 0) {
          dateString = dateString + ","
        }
        //add the date
        if (data.dates[i].format === undefined) {
          let cdf = new DateObject({
            year: data.dates[i].getFullYear(),
            month: data.dates[i].getMonth() + 1,
            day: data.dates[i].getDate(),
          })
          dateString = dateString + cdf.format("YYYY-MM-DD")
        } else {
          dateString = dateString + data.dates[i].format("YYYY-MM-DD")
        }
      }
      gdata.dates = dateString
    } else {
      gdata.dates = ""
    }
    gdata.userFilters = ""
    gdata.adminFilters = ""
    gdata.private = data.underground
    gdata.where = data.address
    //set these to 0 - this will cause the API to grab them.
    gdata.latitude = 0
    gdata.longitude = 0
    if (this.state.subview !== false && this.state.geoji.where === data.address) {
      gdata.latitude = this.state.geoji.geojiLatitude
      gdata.longitude = this.state.geoji.geojiLongitude
      console.log("Address unchanged using initial geoji lat and long", gdata.latitude, gdata.longitude)
    }

    //optionals
    if (data.details) {
      gdata.description = data.details
    }
    if (data.photo) {
      gdata.photoURL = data.photo
    }
    if (data.when) {
      gdata.when = data.when
    }
    if (data.website) {
      gdata.websiteURL = data.website
    }

    //format the contact information
    if (data.contact) {
      let contact = data.contact.trim()
      let numbersOnly = contact.replace(/[^0-9]/g, "")
      let alphasOnly = contact.replace(/[^A-Za-z]/g, "")
      if (numbersOnly.length >= 10 && alphasOnly.length === 0) {
        //most likely a phone number
        contact = numbersOnly
      } else {
        //an instagram handle or email - remove the @ symbol if necessary
        if (contact.indexOf("@") === 0) {
          contact = contact.substring(1)
        }
        //if there is a space, then show an error.
        if (contact.indexOf(' ') !== -1) {
          this.showError("No spaces in the Contact Field", "Please remove the spaces in the Contact info. There can only be one @insta handle to contact.")
          return
        }
      }
      gdata.contactInfo = contact
    }

    //Add in the token JSON data.
    gdata.tokens = JSON.stringify(Helpers.getTokenJSON(this.state.geoji))
    this.setState({
      loading: true
    }, () => {
      if (this.state.subview === false) {
        //create
        console.log("create gdata", gdata)
        API.callDarwinAPI("POST", "geoji", gdata, async (result) => {
          console.log("POST geoji", result)
          if ("error" in result) {
            //Change the view to 404
            this.showError("Couldn't create the Geoji", "Please try again. If this continues to occur, reach out to support@geoji.com")
            this.setState({
              loading: false
            })
            return
          }
          //now open up the geoji
          this.props.changeView("Geoji", result.data.id)
        })
      } else {
        //edit
        gdata.deleteTokenIDs = this.state.geoji.deletedTokens ? JSON.stringify(this.state.geoji.deletedTokens) : JSON.stringify([])
        console.log("edit gdata", gdata)
        API.callDarwinAPI("PUT", "geoji/" + this.state.geoji.geojiID, gdata, async (result) => {
          console.log("PUT geoji", result)
          if ("error" in result) {
            //Change the view to 404
            this.showError("Couldn't edit the Geoji", result.error)
            this.setState({
              loading: false
            })
            return
          }
          //now open up the geoji
          this.props.changeView("Geoji", this.state.geoji.geojiID)
        })
      }
    })
  }

  /*
  Deletes the Geoji
  */
  deleteGeoji() {
    this.showConfirmation("Are you sure you want to delete this Geoji?", "Be doubly sure as this action cannot be undone.", "Delete", "Cancel", (shouldDelete) => {
      if (shouldDelete) {
        this.setState({
          loading: true
        }, () => {
          API.callDarwinAPI("PUT", "expireGeoji/" + this.state.geoji.geojiID, {}, async (result) => {
            console.log("PUT expireGeoji/" + this.state.geoji.geojiID, result)
            if ("error" in result) {
              //Change the view to 404
              this.showError("Couldn't delete the Geoji", result.error)
              this.setState({
                loading: false
              })
              return
            }
            //now back to the geojis list.
            this.setState({
              loading: false,
              geoji: false
            }, () => {
              this.props.changeView("Dashboard", "geojis")
            })
          })
        })
      }
    })
  }

  /*
  Update the form information for this token.
  */
  selectTokenForEditing(token) {
    this.setState((old) => {
      let newData = old.data

      let items = ["tokenName", "tokenAbout", "tokenAboutImage", "tokenFrozen", "tokenUserHidden", "tokenPrice", "tokenQuantity", "tokenPurchaseLimit"]
      let titems = ["name", "about", "aboutImage", "zopq", "zopq", "zopq", "zopq", "zopq"]
      for (let i = 0; i < items.length; i = i + 1) {
        let key = items[i]
        let tkey = titems[i]
        if (newData[key]) {
          delete newData[key]
        }
        if (token[tkey] && token[tkey].length > 0) {
          newData[key] = {
            value: token[tkey],
            valid: true
          }
        }
      }
      newData["tokenFrozen"] = {
        value: token["frozen"],
        valid: true
      }
      newData["tokenUserHidden"] = {
        value: token["userHidden"],
        valid: true
      }
      newData["tokenPurchaseLimit"] = {
        value: token["purchaseLimit"] !== 0 ? token["purchaseLimit"] : "",
        valid: true
      }
      newData["tokenPrice"] = {
        value: (token["priceCents"] / 100).toString(),
        valid: true
      }
      newData["tokenQuantity"] = {
        value: token["quantity"].toString(),
        valid: true
      }

      return {
        selectedToken: token,
        data: newData
      }
    })
  }

  /*
  Creates a new token for the selected Geoji.
  */
  createToken() {
    console.log(this.state.geoji)

    this.setState((old) => {
      let newGeoji = old.geoji

      let newIndex = 0
      for (let i = 0; i < newGeoji.tokens.length; i = i + 1) {
        if (newGeoji.tokens[i].index >= newIndex) {
          newIndex = newGeoji.tokens[i].index + 1
        }
      }

      newGeoji.tokens.push({
        about: null,
        aboutImage: null,
        cart: 0,
        frozen: "0",
        geojiID: newGeoji.geojiID,
        id: "New_" + Helpers.randomString(24),
        index: newIndex,
        name: "",
        priceCents: 0,
        quantity: 0,
        theme: "default",
        purchaseLimit: 0,
        userHidden: 0,
        tokensSold: 0,
      })
      return {
        geoji: newGeoji,
        selectedToken: newGeoji.tokens[newGeoji.tokens.length - 1],
      }
    }, () => {
      this.selectTokenForEditing(this.state.selectedToken)
    })
  }

  /*
  Duplicates the selected token.
  */
  duplicateToken(token) {
    this.setState((old) => {
      let newGeoji = old.geoji
      let dupToken = Object.assign({}, token)
      dupToken.id = "New_" + Helpers.randomString(24)

      let newIndex = 0
      for (let i = 0; i < newGeoji.tokens.length; i = i + 1) {
        if (newGeoji.tokens[i].index >= newIndex) {
          newIndex = newGeoji.tokens[i].index + 1
        }
      }
      dupToken.index = newIndex
      newGeoji.tokens.push(dupToken)

      return {
        geoji: newGeoji,
        selectedToken: dupToken,
      }
    }, () => {
      this.selectTokenForEditing(this.state.selectedToken)
    })
  }

  /*
  Selects the token for editing.
  */
  editToken(token) {
    console.log("editing token", token)
    this.setState({
      selectedToken: token,
    }, () => {
      this.selectTokenForEditing(this.state.selectedToken)
    })
  }

  /*
  Done editing the token.
  */
  finishedEditingToken(data) {
    console.log(data)
    this.setState((old) => {
      let newGeoji = old.geoji
      for (let i = 0; i < newGeoji.tokens.length; i = i + 1) {
        if (newGeoji.tokens[i].id === old.selectedToken.id) {
          //found it
          newGeoji.tokens[i].name = data.tokenName
          newGeoji.tokens[i].priceCents = data.tokenPrice * 100
          newGeoji.tokens[i].quantity = data.tokenQuantity
          newGeoji.tokens[i].about = data.tokenAbout ? data.tokenAbout : null
          newGeoji.tokens[i].aboutImage = data.tokenAboutImage ? data.tokenAboutImage : null
          newGeoji.tokens[i].frozen = data.tokenFrozen
          newGeoji.tokens[i].userHidden = data.tokenUserHidden
          if (data.tokenPurchaseLimit === "" || data.tokenPurchaseLimit === undefined) {
            newGeoji.tokens[i].purchaseLimit = 0
          } else {
            newGeoji.tokens[i].purchaseLimit = (typeof data.tokenPurchaseLimit === 'number') ? data.tokenPurchaseLimit : parseInt(data.tokenPurchaseLimit)
          }
          break
        }
      }
      return {
        selectedToken: false,
        geoji: newGeoji
      }
    })
  }

  /*
  Does nothing, used for the empty methods for tokens.
  */
  emptyMethod() {
  }

  /*
  Removes the token from the token list.
  */
  deleteToken() {
    this.setState((old) => {
      let newGeoji = old.geoji
      for (let i = 0; i < newGeoji.tokens.length; i = i + 1) {
        if (newGeoji.tokens[i].id === old.selectedToken.id) {
          //found it, now remove it from the list.
          if (this.state.selectedToken.id.startsWith("New_")) {
            newGeoji.tokens.splice(i, 1)
          } else {
            //set to be deleted.
            if (newGeoji.deletedTokens) {
              newGeoji.deletedTokens.push(newGeoji.tokens[i].id)
            } else {
              newGeoji.deletedTokens = [newGeoji.tokens[i].id]
            }
            newGeoji.tokens.splice(i, 1)
          }
          break
        }
      }
      return {
        selectedToken: false,
        geoji: newGeoji
      }
    })
  }

  /*
  * Sorts the tokens after they have been dragged.
  */
  onSortTokens(sortedTokens) {
    //console.log("New List of sortedTokens", sortedTokens)
    this.setState((old) => {
      let newGeoji = old.geoji
      newGeoji.tokens = sortedTokens

      //set the indices
      for (let i = 0; i < newGeoji.tokens.length; i = i + 1) {
        newGeoji.tokens[i].index = i
      }

      return {
        geoji: newGeoji
      }
    })
  }

  logout() {
    console.log("Logging out the user")
    //1) Logout the user
    API.logout()
    //2) Return to the home page
    this.props.changeView("Home")
  }

  onToggleTerms() {
    this.setState((old) => {
      return {
        acceptTerms: !old.acceptTerms
      }
    })
  }

  render() {

    let confirmationProps = {
      main: "Confirmation",
      title: this.state.confirmationTitle,
      description: this.state.confirmationDescription,
      accept: this.state.confirmationAccept,
      deny: this.state.confirmationDeny,
      action: this.state.confirmationAction
    }

    let showErrorProps = {
      main: "Error",
      title: this.state.showErrorTitle,
      description: this.state.showErrorDescription,
      dismiss: this.state.showErrorAction
    }

    return (
      <div className="DashboardPage">
        <HomePage.HomeHeader selected="dashboard" openCloseMenu={this.props.openCloseMenu} userInfo={this.props.userInfo} />

        {/* Confirmation */}
        { this.state.confirmation &&
          <Components.Confirmation {...confirmationProps} />
        }
        {/* Error Popopver */}
        { this.state.showError &&
          <Components.FullScreenAlert {...showErrorProps} />
        }
        {/* Loading Indicator */}
        { this.state.loading &&
          <div className="GeojiLoading">
            <img src={logo} className="GeojiLoadingLogo" alt="logo" />
          </div>
        }

        {/* Home View */}
        { this.state.view === "home" && !this.state.loading && this.state.error === false &&
          <div className="DashboardPageContent">
            <h1 className="DashboardPageHeader">
              Dashboard
            </h1>
            <div className="DashboardPageBar">
            </div>
            <div className="DashboardPageActions">
              {/* My Geojis */}
              <div className="DashboardPageAction" onClick={this.changeStateView.bind(this, "geojis")}>
                <img src={ImageBlueGraph} className="DashboardPageActionImage" alt="My Geojis" />
                <h2 className="DashboardPageActionTitle">
                  My Geojis
                </h2>
                <div className="DashboardPageActionBody">
                  Spin up new events or businesses in less than a minute and start making money.
                </div>
              </div>

              {/* My Tickets */}
              {/*<div className="DashboardPageAction" onClick={this.changeStateView.bind(this, "tickets")}>
                <img src={ImageBlueTickets} className="DashboardPageActionImage" alt="My Tickets" />
                <h2 className="DashboardPageActionTitle">
                  My Tickets
                </h2>
                <div className="DashboardPageActionBody">
                  Remember all the amazing events you went to and the memories you made.
                </div>
              </div>*/}
            </div>

            <div className="DashboardPageButtons">
              {/* Logout */}
              <div className="DashboardPageButton" onClick={this.logout.bind(this)}>
                Logout
              </div>
            </div>
          </div>
        }

        {/* My Geojis View */}
        { this.state.view === "geojis" && !this.state.loading && this.state.error === false &&
          <div className="DashboardPageContent">
            <h1 className="DashboardPageHeader">
              My Geojis
            </h1>
            <div className="DashboardPageBar">
            </div>
            {/* Create Geoji Button */}
            <div className="CreateButton" onClick={this.changeStateView.bind(this, "create")}>
              Create New Geoji
            </div>
            <div className="DashboardPageGeojis">
              {/* Geojis */}
              { this.state.geojis.map((geoji) => (
                <div className="HandlePageGeoji" key={"geoji_" + geoji.geojiID}>
                  <div className="HandlePageGeojiBody">
                    <div className="HandlePageGeojiBody">
                      { geoji.photoURL && geoji.photoURL.length > 0 &&
                        <img src={geoji.photoURL} alt={geoji.title} className="HandlePageGeojiImage" />
                      }
                      { !(geoji.photoURL && geoji.photoURL.length > 0) &&
                        <div className="HandlePageGeojiEmoji">
                          {geoji.emoji}
                        </div>
                      }
                      <div className="HandlePageGeojiRight">
                        <div className="HandlePageGeojiRightTop">
                          <div className="HandlePageGeojiRightDate">
                            { geoji.infinite &&
                              <img src={ImageInfinity} alt="Infinity"/>
                            }
                            { !geoji.infinite &&
                              <span>{Helpers.geojiGetNextEventDateString(geoji, "tiny")}</span>
                            }
                          </div>
                          <div className="HandlePageGeojiRightDateOptions">
                            <div className="HandlePageGeojiRightButtonOpen" onClick={this.viewGeoji.bind(this, geoji)}>
                              OPEN
                            </div>
                            { geoji.role === "Owner" &&
                              <div className="HandlePageGeojiRightButtonEdit" onClick={this.editGeoji.bind(this, geoji)}>
                                EDIT
                              </div>
                            }
                          </div>
                        </div>
                        <div className="HandlePageGeojiRightTitle">
                          {geoji.title}
                        </div>
                        { geoji.when && geoji.when.length > 0 &&
                          <div className="HandlePageGeojiRightWhen">
                            <img src={ImageClock} alt="Clock" />
                            <div className="HandlePageGeojiRightWhenText">
                              {geoji.when}
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        }

        {/* TODO - PUT THE WYSIWYG Editor stuff here found in the file WYSIWYG.txt */}

        {/* Old - Create Geoji */}
        { this.state.view === "create" && !this.state.loading && this.state.error === false && this.state.selectedToken === false &&
          <div className="DashboardPageContent">
            <h1 className="DashboardPageHeader">
              { this.state.subview !== false ? "Edit Geoji" : "Create Geoji" }
            </h1>
            <div className="DashboardPageBar">
            </div>
            <div className="DashboardPageEditor">
              {/* Geoji Form Here */}
              {/* Public/Underground Toggle */}
              <Components.SettingsToggle name="Public" name2="Underground" value={this.state.data.underground.value === "1"} onToggle={this.onToggle.bind(this, "underground")} />
              <div className="SettingsToggleBottom">
                {this.state.data.underground.value === "1" ? "Keeps Karens out. Only people with the shared link can see your Geoji." : "Anyone can see your Geoji."}
              </div>
              {/* Address */}
              <div className="InputDiv">
                <Components.InputBottomLine type="googleAddress" name="address" placeholder="Location or Address" validation="text" required="true"
                  title="Address" maximum={100} addressSelected={this.googleAddressSelected.bind(this)}
                  value={this.state.data.address ? this.state.data.address.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
              </div>
              {/* Title */}
              <div className="InputDiv">
                <Components.InputBottomLine type="text" name="title" validation="text" required="true" maximum={255} placeholder="Backyard Concert"
                  title="Title"
                  value={this.state.data.title ? this.state.data.title.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
              </div>
              {/* Emoji */}
              <div className="InputDiv">
                <Components.InputBottomLine type="text" name="emoji" validation="text" required="false" maximum={100} placeholder="❔"
                  title="Emoji - type to search"
                  value={this.state.data.emoji ? this.state.data.emoji.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
              </div>
              <Components.EmojiList emojis={this.state.emojis} onSelect={this.emojiSelected.bind(this)} />
              <Components.EmojiList emojis={this.state.likeEmojis} onSelect={this.emojiSelected.bind(this)} style={{marginTop:"8px"}} title="Similar" />
              <div className="DashboardPageEditorBreak" />

              {/* Forever Toggle */}
              <Components.SettingsToggle name="Dates" name2="Forever" value={this.state.data.forever.value === "1"} onToggle={this.onToggle.bind(this, "forever")} />
              <div className="SettingsToggleBottom">
                {this.state.data.forever.value === "1" ? "This Geoji never expires." : "This Geoji happens on specific date(s)."}
              </div>
              {/* Event Dates */}
              { this.state.data.forever.value === "0" &&
                <div className="InputDiv">
                  <Components.InputBottomLine type="multipledates" name="dates" validation="multipledates" required="false"
                    title="Event Dates"
                    value={this.state.data.dates ? this.state.data.dates.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                </div>
              }
              {/* When */}
              <div className="InputDiv">
                <Components.InputBottomLine type="text" name="when" validation="text" required="false" maximum={255} placeholder="6pm - 10pm"
                  title="Time"
                  value={this.state.data.when ? this.state.data.when.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
              </div>
              <div className="DashboardPageEditorBreak" />

              <div className="DashboardPageEditorSectionTitle">
                Optional Fields
              </div>
              {/* Photo */}
              <div className="InputDiv">
                <Components.InputBottomLine type="file" name="photo" placeholder="" validation="file" required="false"
                  title="Photo" description=""
                  APIName="userFiles" APIUploadName="userFiles" showPopup={this.props.showPopup} maxSize={1920}
                  value={this.state.data.photo ? this.state.data.photo.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
              </div>
              {/* Details */}
              <div className="InputDiv">
                <Components.InputBottomLine type="textarea" name="details" validation="text" required="false" maximum={4000} placeholder="Come join us and have fun."
                  title="Details"
                  value={this.state.data.details ? this.state.data.details.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
              </div>
              {/* Website URL */}
              <div className="InputDiv">
                <Components.InputBottomLine type="text" name="website" validation="urlSimple" required="false" maximum={255} placeholder="https://www.mysite.com"
                  title="Website URL"
                  value={this.state.data.website ? this.state.data.website.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
              </div>
              {/* Contact */}
              <div className="InputDiv">
                <Components.InputBottomLine type="text" name="contact" validation="text" required="false" maximum={255} placeholder="@insta or phone#"
                  title="Contact"
                  value={this.state.data.contact ? this.state.data.contact.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
              </div>
              <div className="DashboardPageEditorBreak" />

              {/* TOKENS */}
              <div className="DashboardPageEditorSectionTitle">
                Tokens
              </div>
              <div className="DashboardPageEditorTokens">
                { this.state.geoji && this.state.geoji.tokens && this.state.geoji.tokens.length > 0 &&
                  <div className="DashboardPageEditorTokensList">
                    { this.state.geoji.tokens.map((token, i) => (
                      <Geoji.SortableGeojiToken key={"token_" + token.id}
                        onSortItems={this.onSortTokens.bind(this)}
                        items={this.state.geoji.tokens}
                        sortId={i}
                        token={token} geoji={this.state.geoji}
                        editing={true} onEdit={this.editToken.bind(this)} onCopy={this.duplicateToken.bind(this)}
                        decrementToken={this.emptyMethod.bind(this)}
                        incrementToken={this.emptyMethod.bind(this)}
                        tokenChanged={this.emptyMethod.bind(this)}
                        selectedTokenAutoTip={this.emptyMethod.bind(this)}
                        promoCodeChanged={this.emptyMethod.bind(this)}
                        promoCodeSubmit={this.emptyMethod.bind(this)}
                        selectedTokenMapSeat={this.emptyMethod.bind(this)}
                        tokenDateTimeSelected={this.emptyMethod.bind(this)}
                        tokenDateTimeTimeSelected={this.emptyMethod.bind(this)}
                      />
                    ))}
                  </div>
                }
                <div className="AddButton" onClick={this.createToken.bind(this)}>
                  Add Token
                </div>
              </div>
              <div className="DashboardPageEditorBreak" />

              {/* Delete Geoji Button */}
              { this.state.geoji !== undefined && this.state.geoji !== false &&
                <div className="DeleteButton" onClick={this.deleteGeoji.bind(this)}>
                  Delete Geoji
                </div>
              }
              {/* Create Geoji Button */}
              <div className="CreateButton" onClick={this.submitForm}>
                {this.state.geoji !== undefined && this.state.geoji !== false ? "Save Changes" : "Create Geoji"}
              </div>
            </div>
          </div>
        }

        {/* Old - Token Editor */}
        { this.state.view === "create" && !this.state.loading && this.state.error === false && this.state.selectedToken !== false &&
          <div className="DashboardPageContent">
            <h1 className="DashboardPageHeader">
              Token Editor
            </h1>
            <div className="DashboardPageBar">
            </div>
            <div className="DashboardPageEditor" style={{paddingTop:"16px"}}>

              {/* Freeze/Unfreeze */}
              <Components.SettingsToggle name="Frozen" name2="Active" value={!this.state.data.tokenFrozen || this.state.data.tokenFrozen.value !== "1"} onToggle={this.onToggle.bind(this, "tokenFrozen")} />

              {/* Token Name */}
              <div className="InputDiv">
                <Components.InputBottomLine type="text" name="tokenName" validation="text" required="true" maximum={255} placeholder="Pink Lemonade"
                  title="Name"
                  value={this.state.data.tokenName ? this.state.data.tokenName.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
              </div>

              {/* Token Price */}
              <div className="InputDiv">
                <Components.InputBottomLine type="text" name="tokenPrice" validation="money" required="true" placeholder="0 for free, otherwise the price per item"
                  title="Price" minimum={0.0} maximum={100000.00}
                  value={this.state.data.tokenPrice ? this.state.data.tokenPrice.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
              </div>

              {/* Token Quantity */}
              <div className="InputDiv">
                <Components.InputBottomLine type="text" name="tokenQuantity" validation="digits" required="false" placeholder="# of tokens for sale, or leave blank for unlimited"
                  title="Quantity" minimum={0}
                  value={this.state.data.tokenQuantity ? this.state.data.tokenQuantity.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
              </div>

              <div className="DashboardPageEditorBreak" />

              <div className="DashboardPageEditorSectionTitle">
                Optional
              </div>

              {/* About */}
              <div className="InputDiv">
                <Components.InputBottomLine type="textarea" name="tokenAbout" validation="text" required="false" maximum={4000} placeholder="12oz drink with a drop of sweet"
                  title="About"
                  value={this.state.data.tokenAbout ? this.state.data.tokenAbout.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
              </div>

              {/* About Image */}
              <div className="InputDiv">
                <Components.InputBottomLine type="file" name="tokenAboutImage" placeholder="" validation="file" required="false"
                  title="Image" description=""
                  APIName="userFiles" APIUploadName="userFiles" showPopup={this.props.showPopup} maxSize={1920}
                  value={this.state.data.tokenAboutImage ? this.state.data.tokenAboutImage.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
              </div>

              <div className="DashboardPageEditorBreak" />

              <div className="DashboardPageEditorSectionTitle">
                Advanced
              </div>

              {/* User Hidden */}
              <Components.SettingsToggle name="Hidden" name2="Visible" value={!this.state.data.tokenUserHidden || this.state.data.tokenUserHidden.value !== 1} onToggle={this.onToggle.bind(this, "tokenUserHidden")} />
              <div className="SettingsToggleDetails">
                Is this token visible to customers?
                <br/>
                If not it will appear in Virtual Terminal but not on the customer facing Geoji.
                This is useful if you want your customers to be able to buy tickets in advance but not certain items like merchandise.
              </div>

              {/* Purchase Limit */}
              <div className="InputDiv">
                <Components.InputBottomLine type="text" name="tokenPurchaseLimit" validation="digits" required="false" placeholder="max # of tokens a customer can buy"
                  title="Max # a customer can buy" description="Leave blank for unlimited" minimum={0}
                  value={this.state.data.tokenPurchaseLimit ? this.state.data.tokenPurchaseLimit.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
              </div>

              {/* Delete Geoji Button */}
              { (this.state.selectedToken.id.startsWith("New_") || this.state.selectedToken.tokensSold === 0) &&
                <div className="DeleteButton" onClick={this.deleteToken.bind(this)}>
                  Delete Token
                </div>
              }
              {/* Create Geoji Button */}
              <div className="CreateButton" onClick={this.submitForm}>
                Done
              </div>
            </div>
          </div>
        }

        {/* Footer */}
        <Components.GeojiFooter nohide={true} />
      </div>
    )
  }
}
